import { useState } from "react";
import { Navbar } from "./components/Navbar";
import "./App.css";
import { Footer } from "./components/Footer";
import { Route, Routes, useLocation } from "react-router-dom"; // useLocation eklendi
import { Home } from "./components/Home";
import { Kurumsal } from "./components/pages/Kurumsal";
import { Products } from "./components/pages/Products";
import { Contact } from "./components/pages/Contact";
import { Mission } from "./components/pages/Mission";
import { HomeFurniture } from "./components/pages/ProductsContent/HomeFurniture";

function App() {
  const [count, setCount] = useState(0);
  const location = useLocation();
  const noFooterPaths = ["/ev-mobilyalari"];

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/kurumsal" element={<Kurumsal />} />
        <Route path="/urunler" element={<Products />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/misyon" element={<Mission />} />
        <Route path="/ev-mobilyalari" element={<HomeFurniture />} />
      </Routes>
      {!noFooterPaths.includes(location.pathname) && <Footer />}
    </>
  );
}

export default App;
