import React, { useState, useEffect } from "react";
import "./HomeFurniture.css";

export const HomeFurniture = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    fetch("./data/product.json")
      .then((response) => response.json())
      .then((data) => setProducts(data))
      .catch((error) => console.error("Data çekilemiyor!", error));
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [isOpen]);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setIsOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (
      isOpen &&
      !event.target.closest("#default-sidebar") &&
      !event.target.closest("#toggle-sidebar-button")
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const filteredProducts =
    selectedCategory === "all"
      ? products
      : products.filter((product) => product.category === selectedCategory);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <button
        id="toggle-sidebar-button"
        style={{ marginTop: "5rem" }}
        onClick={toggleSidebar}
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg block lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          ></path>
        </svg>
      </button>

      <div className="container">
        <aside
          style={{
            marginTop: "8rem",
            overflowY: "auto",
            maxHeight: "calc(100vh - 8rem)",
          }}
          id="default-sidebar"
          className={`fixed top-0 left-0 z-40 w-64 rounded h-screen transition-transform ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          } lg:translate-x-0`}
          aria-label="Sidebar"
        >
          <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
            <h1
              style={{
                textAlign: "center",
                color: "#C18B3F",
                textDecoration: "",
              }}
            >
              EV MOBİLYALARI
            </h1>
            <hr />
            <ul className="font-medium">
              <li>
                <button
                  onClick={() => handleCategoryClick("all")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color: selectedCategory === "all" ? "#C18B3F" : "inherit",
                    }}
                  >
                    TÜM ÜRÜNLER
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("karyola-ve-komodin")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "karyola-ve-komodin"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    KARYOLA VE KOMODİN
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("dolap")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "dolap" ? "#C18B3F" : "inherit",
                    }}
                  >
                    DOLAP
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("şifonyer")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "şifonyer" ? "#C18B3F" : "inherit",
                    }}
                  >
                    ŞİFONYER
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("çamaşırlık")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "çamaşırlık"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    ÇAMAŞIRLIK
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("konsol")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "konsol" ? "#C18B3F" : "inherit",
                    }}
                  >
                    KONSOL
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("ayna")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "ayna" ? "#C18B3F" : "inherit",
                    }}
                  >
                    AYNA
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("masa")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "masa" ? "#C18B3F" : "inherit",
                    }}
                  >
                    MASA
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("sandalye")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "sandalye" ? "#C18B3F" : "inherit",
                    }}
                  >
                    SANDALYE
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("tv-ünitesi")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "tv-ünitesi"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    TV ÜNİTESİ
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("orta-sehpa")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "orta-sehpa"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    ORTA SEHPA
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("yan-sehpa")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "yan-sehpa"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    YAN SEHPA
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("koltuk")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "koltuk" ? "#C18B3F" : "inherit",
                    }}
                  >
                    KOLTUK
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("dinlenmeli-koltuk")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "dinlenmeli-koltuk"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    DİNLENMELİ KOLTUK
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("Köşe Koltuk")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-black-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "Köşe Koltuk"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    KÖŞE KOLTUK
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("arc-koltuk")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "arc-koltuk"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    ARC KOLTUK
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("berjer")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "berjer" ? "#C18B3F" : "inherit",
                    }}
                  >
                    BERJER
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("vitrin")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "vitrin" ? "#C18B3F" : "inherit",
                    }}
                  >
                    VİTRİN
                  </span>
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleCategoryClick("mutfak-mobilyalari")}
                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group"
                >
                  <span
                    className="flex-1 ms-3 whitespace-nowrap"
                    style={{
                      color:
                        selectedCategory === "mutfak-mobilyalari"
                          ? "#C18B3F"
                          : "inherit",
                    }}
                  >
                    MUTFAK MOBİLYALARI
                  </span>
                </button>
              </li>
            </ul>
          </div>
        </aside>
      </div>
      <div id="content" style={{ marginTop: "4rem" }} className="p-4 lg:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">
            {filteredProducts.map((product) => (
              <div
                key={product.id}
                className="mx-auto mt-11 w-80 transform overflow-hidden rounded-lg bg-white dark:bg-slate-800 shadow-md duration-300 hover:scale-105 hover:shadow-lg"
              >
                <img
                  className="h-48 w-full object-cover object-center"
                  src={product.image}
                  alt={product.name}
                />
                <div className="p-4">
                  <h2 className="mb-2 text-lg font-medium dark:text-white text-gray-900">
                    {product.name}
                  </h2>
                  <p className="mb-2 text-base dark:text-gray-300 text-gray-700">
                    {product.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {showScrollToTop && (
        <button className="scroll-to-top show" onClick={scrollToTop}>
          &uarr;
        </button>
      )}
    </>
  );
};
