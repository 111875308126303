import React, { useEffect } from "react";
import kurumsalImg from "../../assets/concept.png";
import "./Kurumsal.css";
import AOS from "aos";
import "aos/dist/aos.css";

export const Kurumsal = () => {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-wrap">
          <div
            className="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden"
            data-aos="zoom-out"
          >
            <img
              alt="feature"
              className="object-cover object-center w-full"
              src={kurumsalImg}
            />
          </div>
          <div
            className="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center"
            data-aos="zoom-out"
          >
            <div className="flex flex-col mb-10 lg:items-start items-center">
              <div className="flex-grow">
                <h2
                  style={{ color: "black" }}
                  className="text-gray-900 text-lg title-font font-medium mb-3"
                >
                  Estetik ve İşlevsellikte Mükemmellik: Nima Concept
                </h2>
                <p className="leading-relaxed text-base">
                  Nima Concept, mobilya sektöründe yenilikçi tasarımları ve
                  üstün kaliteli ürünleri ile fark yaratan bir marka olarak öne
                  çıkmaktadır. Kuruluşumuzdan bu yana, estetik ve işlevselliği
                  kusursuz bir şekilde bir araya getirerek yaşam alanlarına
                  değer katmayı ilke edindik. Her bir mobilya parçamız, yalnızca
                  bir eşya olmanın ötesinde, bir yaşam tarzının ve sanatın
                  ifadesi olarak tasarlandı.
                </p>
                <br />
                <hr />
                <br />
                <p>
                  İlhamını modern yaşamdan ve doğanın zarafetinden alan
                  tasarımlarımız, her detayda gösterdiğimiz titizliğin ve yüksek
                  standartlarımızın bir yansımasıdır. Her ürünümüz, ince
                  işçilikle ve üstün malzeme kalitesiyle hayat bulur. Nima
                  Concept olarak, mobilyalarımızın her biri, kullanıcısına hem
                  estetik bir haz hem de üstün konfor sunacak şekilde
                  tasarlanmıştır.
                </p>
                <br />
                <hr />
                <br />
                <p>
                  Yaşam alanlarının, kişisel tarzın ve yaşam kalitesinin birer
                  yansıması olduğuna inanıyoruz. Bu doğrultuda, müşteri
                  memnuniyetini en üst seviyede tutarak, kişisel zevk ve
                  ihtiyaçlara uygun çözümler sunmayı amaçlıyoruz.
                  Tasarımlarımız, evinizin her köşesinde fark yaratarak, size
                  özgü ve benzersiz alanlar yaratmanızı sağlar.
                </p>
                <br />
                <hr />
                <br />
                <p>
                  Nima Concept ile yaşam alanlarınızı sıradanlıktan kurtararak,
                  estetik ve fonksiyonelliğin mükemmel birleşimini deneyimleyin.
                  Zarif çizgiler, modern dokunuşlar ve zamansız tasarımlarla,
                  her bir mobilya parçamız, evinizin en değerli parçalarından
                  biri olmaya aday. Bizimle birlikte, evinizi sadece bir yaşam
                  alanı olmaktan çıkarıp, estetik bir sığınak haline getirin.
                </p>
                <br />
                <hr />
                <br />
                <p>
                  Nima Concept olarak, yaşam alanlarınıza sadece mobilya değil,
                  aynı zamanda bir sanat eseri sunmanın gururunu yaşıyoruz. Bu
                  nedenle, detaylarda saklı mükemmelliği ve yüksek
                  standartlarımızı her zaman koruyarak, evinize değer katmaya
                  devam ediyoruz.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
