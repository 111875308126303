import React, { useState, useEffect } from "react";
import HeroSection from "./HeroSection";
import pic1 from "../assets/anasayfa1.jpg";
import pic2 from "../assets/anasayfa2.jpg";
import pic3 from "../assets/anasayfa3.jpg";
import pic4 from "../assets/anasayfa4.jpg";
import pic5 from "../assets/anasayfa5.jpg";
import pic6 from "../assets/anasayfa6.jpg";
import mobil1 from "../assets/berjer-m.jpg";
import mobil2 from "../assets/genc-m.jpg";
import mobil3 from "../assets/masa-m.jpg";
import mobil4 from "../assets/ofis-m.jpg";
import mobil5 from "../assets/projeler-m.jpg";
import mobil6 from "../assets/takim-m.jpg";

export const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = isMobile
    ? [mobil1, mobil2, mobil6, mobil3, mobil4, mobil5]
    : [pic1, pic2, pic3, pic4, pic5, pic6];

  return (
    <>
      <HeroSection />
      <div
        style={{ marginTop: "8rem", marginBottom: "30px" }}
        className="flex flex-col items-center min-h-screen"
      >
        <div style={{ maxWidth: "1279px" }} className="max-w-screen-lg w-full ">
          <div className="flex justify-center">
            <div
              className="p-1 cursor-pointer"
              style={{ maxWidth: "100%", height: "auto" }}
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src={images[4]}
                alt="Top"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center flex-wrap md:flex-nowrap">
            <div
              className="p-1 cursor-pointer"
              style={{ flex: "1", height: "auto" }}
            >
              <img
                src={images[0]}
                alt="Middle 1"
                className="w-full h-full object-cover"
              />
            </div>
            <div
              className="p-1 cursor-pointer"
              style={{ flex: "1", height: "auto" }}
            >
              <img
                src={images[2]}
                alt="Middle 2"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row justify-center flex-wrap md:flex-nowrap">
            <div
              className="p-1 cursor-pointer"
              style={{ flex: "1", height: "auto" }}
            >
              <img
                src={images[1]}
                alt="Bottom 1"
                className="w-full h-full object-cover"
              />
            </div>
            <div
              className="p-1 cursor-pointer"
              style={{ flex: "1", height: "auto" }}
            >
              <img
                src={images[3]}
                alt="Bottom 2"
                className="w-full h-full object-cover"
              />
            </div>
            <div
              className="p-1 cursor-pointer"
              style={{ flex: "1", height: "auto" }}
            >
              <img
                src={images[5]}
                alt="Bottom 3"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
