import React, { useState, useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import "./Navbar.css";
import { Link } from "react-router-dom";
import Logo from "../assets/concept-logo.png";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Toggle } from "@/components/ui/toggle";

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1026px)" });
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const productDropDownRef = useRef(null);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleProductMenu = (event) => {
    if (
      productDropDownRef.current &&
      !productDropDownRef.current.contains(event.target)
    ) {
      setIsProductOpen(false);
    }
  };

  const toggleMenu = (event) => {
    event.stopPropagation();
    setIsMenuOpen((prevIsMenuOpen) => {
      if (!prevIsMenuOpen) {
        console.log("menü açıldı");
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
      return !prevIsMenuOpen;
    });
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  useEffect(() => {
    if (!isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    document.addEventListener("mousedown", handleProductMenu);
    return () => {
      document.removeEventListener("mousedown", handleProductMenu);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`text-gray-600 body-font fixed top-0 left-0 w-full shadow-lg z-50 transition-colors duration-300 ${
          isScrolled ? "scrolled" : ""
        }`}
        style={{
          background:
            isScrolled || isHovered || isMenuOpen
              ? "white"
              : "linear-gradient(to bottom, rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0))",
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="container mx-auto flex flex-wrap flex-row items-center justify-between">
          <Link
            to={"/"}
            className="flex title-font font-medium items-center text-gray-900"
          >
            <img
              src={Logo}
              alt="Nima Concept Logo"
              style={{
                height: "140px",
                filter:
                  isScrolled || isHovered ? "invert(1) brightness(10)" : "none",
              }}
              className="nav-logo"
            />
          </Link>
          {isMobile ? (
            <Sheet>
              <SheetTrigger>
                <div>
                  <Toggle className="flex flex-col justify-between w-6 h-5">
                    <div
                      style={{
                        height: "4px",
                        width: "20px",
                        backgroundColor:
                          isScrolled || isHovered ? "black" : "white",
                      }}
                      className="h-1"
                    ></div>
                    <div
                      style={{
                        height: "4px",
                        width: "20px",
                        backgroundColor:
                          isScrolled || isHovered ? "black" : "white",
                      }}
                      className="h-1"
                    ></div>
                    <div
                      style={{
                        height: "4px",
                        width: "20px",
                        backgroundColor:
                          isScrolled || isHovered ? "black" : "white",
                      }}
                      className="h-1"
                    ></div>
                  </Toggle>
                </div>
              </SheetTrigger>
              <SheetContent>
                <SheetHeader>
                  <SheetTitle>Menü</SheetTitle>
                  <hr />
                  <SheetDescription asChild>
                    <div className="flex flex-col space-y-6">
                      <Link to={"/"} className="nav-item text-black">
                        Ana Sayfa
                      </Link>
                      <DropdownMenu>
                        <DropdownMenuTrigger className="nav-item text-black">
                          Kurumsal
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <Link to="/">
                            <DropdownMenuItem>Hakkımızda</DropdownMenuItem>
                          </Link>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>Kurumsal Kimlik</DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>Üretimimiz</DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>Ar-Ge</DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>Tasarım</DropdownMenuItem>
                          <DropdownMenuItem>Misyon</DropdownMenuItem>
                          <DropdownMenuItem>Vizyon</DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                      <DropdownMenu>
                        <DropdownMenuTrigger className="nav-item text-black">
                          Ürünler
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuItem>Ev Mobilyaları</DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>
                            Outdoor Mobilyalar
                          </DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>Çocuk Mobilyaları</DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>Ofis Mobilyaları</DropdownMenuItem>
                          <DropdownMenuSeparator />
                          <DropdownMenuItem>
                            İç Mekan Mobilyaları
                          </DropdownMenuItem>
                        </DropdownMenuContent>
                      </DropdownMenu>
                      <a href="#" className="nav-item text-black">
                        Projeler
                      </a>
                      <a href="#" className="nav-item text-black">
                        Çözüm Ortakları
                      </a>
                      <a href="#" className="nav-item text-black">
                        E-Katalog
                      </a>
                      <Link to={"/"} className="nav-item text-black">
                        İletişim
                      </Link>
                    </div>
                  </SheetDescription>
                </SheetHeader>
              </SheetContent>
            </Sheet>
          ) : (
            <nav className="hidden md:flex flex-wrap items-center text-base justify-center w-full md:w-auto">
              <Link to={"/"} className="nav-item hover:text-gray-900 mx-2">
                Ana Sayfa
              </Link>
              <DropdownMenu>
                <DropdownMenuTrigger className="nav-item hover:text-gray-900 mx-2">
                  Kurumsal
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <Link to="/">
                    <DropdownMenuItem>Hakkımızda</DropdownMenuItem>
                  </Link>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Kurumsal Kimlik</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Üretimimiz</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Ar-Ge</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Tasarım</DropdownMenuItem>
                  <DropdownMenuItem>Misyon</DropdownMenuItem>
                  <DropdownMenuItem>Vizyon</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <DropdownMenu>
                <DropdownMenuTrigger className="nav-item hover:border-none hover:text-gray-900 mx-2">
                  Ürünler
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem>Ev Mobilyaları</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Outdoor Mobilyalar</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Çocuk Mobilyaları</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>Ofis Mobilyaları</DropdownMenuItem>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem>İç Mekan Mobilyaları</DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <a href="#" className="nav-item hover:text-gray-900 mx-2">
                Projeler
              </a>
              <a href="#" className="nav-item hover:text-gray-900 mx-2">
                Çözüm Ortakları
              </a>
              <a href="#" className="nav-item hover:text-gray-900 mx-2">
                E-Katalog
              </a>
              <Link to={"/"} className="nav-item hover:text-gray-900 mx-2">
                İletişim
              </Link>
            </nav>
          )}
        </div>
      </header>
    </>
  );
};
