import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const Mission = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <div className="bg-white">
        <section
          style={{ marginTop: "3rem" }}
          id="features"
          className="relative block py-10 md:py-20 "
        >
          <div
            className="relative mx-auto w-full text-center my-4"
            data-aos="fade-right"
          >
            <span
              style={{ color: "#C18B3F" }}
              className="my-3 flex items-center justify-center font-medium tracking-wider"
            >
              Misyonumuz
            </span>
            <h2 className="block w-full bg-gradient-to-b from-black to-gray-400 bg-clip-text font-bold text-transparent text-3xl sm:text-4xl">
              Nima Concept Misyonu
            </h2>
            <p className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-600">
              Nima Concept olarak misyonumuz, yaşam ve çalışma alanlarını
              müşterilerimizin benzersiz kişilikleri ve yaşam tarzlarını
              yansıtan, güzel, işlevsel ve ilham verici mekânlara
              dönüştürmektir.
            </p>
          </div>
          <div class="h-1 bg-gray-200 rounded overflow-hidden">
            <div
              style={{
                width: "6rem",
                height: "100%",
                backgroundColor: "#C18B3F",
              }}
            ></div>
          </div>
          <div className="relative mx-auto w-full z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
            <div
              style={{ background: "black", opacity: "0.83" }}
              className="rounded-3xl p-8 text-center shadow  mx-3"
              data-aos="fade-right"
            >
              <h3 style={{ color: "#C18B3F" }} className="mt-6 text-gray-400">
                Mekânlara Ruh ve Karakter Katmak
              </h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-300">
                Mobilyanın sadece bir nesne topluluğundan ibaret olmadığını,
                konforun, tarzın ve bir mekânın ruhunun özü olduğunu
                düşünüyoruz. Her bir mobilya parçasının, bulunduğu ortama
                karakter ve sıcaklık katması gerektiğine inanıyoruz.
              </p>
            </div>

            <div
              style={{ background: "black", opacity: "0.83" }}
              className="rounded-3xl bg-neutral-900/50 p-8 text-center shadow  mx-3"
              data-aos="fade-right"
            >
              <h3 style={{ color: "#C18B3F" }} className="mt-6 text-gray-400">
                Mükemmeliyet ve Sürdürülebilirlikte Öncü
              </h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-300">
                Kaliteli işçilik ve yenilikçi tasarıma olan bağlılığımız, hem
                estetik açıdan hoş hem de dayanıklı ve pratik parçalar
                yaratmamızı sağlıyor. Koleksiyonumuzdaki her ürün titizlikle
                üretilmekte olup, en yüksek mükemmeliyet ve sürdürülebilirlik
                standartlarına uygun olarak hazırlanmıştır.
              </p>
            </div>

            <div
              style={{ background: "black", opacity: "0.83" }}
              className="rounded-3xl bg-neutral-900/50 p-8 text-center shadow  mx-3"
              data-aos="fade-right"
            >
              <h3 style={{ color: "#C18B3F" }} className="mt-6 text-gray-400">
                Zamansız Zarafet ve Çağdaş Şıklık
              </h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-300">
                Çeşitli zevk ve tercihlere hitap eden geniş bir ürün yelpazesi
                sunmayı hedefliyoruz; zamansız klasiklerden çağdaş trendlere
                kadar. Tasarımlarımız, en son küresel stillerden ilham alarak,
                her parçayı zarafet ve sofistikasyonun bir ifadesi haline
                getiren benzersiz sanatsal vizyonumuzla harmanlanmıştır.
              </p>
            </div>
          </div>

          <div className="relative mx-auto w-full z-10 grid grid-cols-1 gap-10 pt-14">
            <div
              style={{ background: "black", opacity: "0.83" }}
              className="rounded-3xl bg-neutral-900/50 p-8 text-center shadow mx-3"
              data-aos="fade-right"
            >
              <h3 style={{ color: "#C18B3F" }} className="mt-6 text-gray-400">
                Kişiye Özel Hizmet ve Mükemmel Mobilya Çözümleri
              </h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-300">
                Nima Concept olarak her müşterimizin farklı ihtiyaçları ve
                hayalleri olduğunu anlıyoruz. Bu nedenle, müşterilerimize yaşam
                ve çalışma alanlarını güzelleştirecek mükemmel mobilya
                çözümlerini bulmalarında yardımcı olmak için kişiye özel hizmet
                sunuyoruz. Her müşterimiz için özenle tasarlanmış ve onların
                zevklerine uygun mobilyalar öneriyoruz. İster modern ister
                klasik tarza sahip olun, geniş ürün yelpazemizle her zevke hitap
                eden çözümler sunuyoruz. Amacımız, her müşterimizin hayalindeki
                yaşam alanını gerçeğe dönüştürmektir. Bu süreçte, kaliteli
                işçilik ve yenilikçi tasarımlardan ödün vermeden, müşteri
                memnuniyetini en üst düzeyde tutmayı hedefliyoruz.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
