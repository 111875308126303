import React, { useState } from "react";
import productImg from "../../assets/img3.jpeg";
import productImg2 from "../../assets/product2.jpg";
import productImg3 from "../../assets/product3.jpg";
import productImg4 from "../../assets/product4.jpg";
import productImg5 from "../../assets/product5.png";
import productImg6 from "../../assets/product6.jpg";
import productImg7 from "../../assets/product7.jpg";
import productImg8 from "../../assets/product8.png";

import "./Products.css";
import { HomeFurniture } from "./ProductsContent/HomeFurniture";

export const Products = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (imageSrc) => {
    setModalImage(imageSrc);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setModalImage("");
  };

  return (
    <>
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-wrap w-full mb-20">
            <div className="lg:w-1/2 w-full mb-6 lg:mb-0">
              <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
                Nima Concept: Estetik ve Fonksiyonelliğin Buluştuğu Nokta
              </h1>
              <div
                style={{ background: "#C18B3F" }}
                className="h-1 w-20 rounded"
              ></div>
            </div>
            <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">
              Nima Concept olarak, yaşam ve çalışma alanlarınızı dönüştürecek
              mobilya çözümleri sunuyoruz. Estetik, konfor ve dayanıklılığı bir
              araya getirerek, her mekana özel tasarımlar yapıyoruz. Ofislerden
              otellere, restoranlardan evlere kadar geniş bir yelpazede
              sunduğumuz ürünlerle, mekanlarınıza değer katıyoruz. Yüksek
              kaliteli malzemeler ve özenli işçilikle üretilen mobilyalarımızla,
              hem göz alıcı hem de kullanışlı mekanlar yaratıyoruz. Ürünlerimizi
              keşfedin ve Nima Concept'in farkını hissedin.
            </p>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Mutfak Takımı
                </h2>
                <p className="leading-relaxed text-base">
                  Nima Concept ile mutfaklarınıza şıklık ve işlevsellik katın,
                  her anın tadını çıkarın.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg2)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg2}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Modern ve Şık Koltuk
                </h2>
                <p className="leading-relaxed text-base">
                  Nima Concept, renkli yastıkları ve zarif tasarımıyla evinize
                  şıklık katıyor.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg3)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg3}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Minimalist Koltuk
                </h2>
                <p className="leading-relaxed text-base">
                  Nima Concept, sade ve zarif tasarımıyla modern yaşam alanları
                  için ideal.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg4)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg4}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Zarif Köşe Koltuk Takımı
                </h2>
                <p className="leading-relaxed text-base">
                  Nima Concept, zarif tasarımı ve konforlu yapısıyla yaşam
                  alanlarınıza şıklık katıyor.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg5)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg5}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Modern ve Şık Yatak Odası Takımı
                </h2>
                <p className="leading-relaxed text-base">
                  Yeni yatak odası, minimalist çizgileri ve zarif detaylarıyla
                  estetik ve konfor katıyor.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg6)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg6}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Zarif ve Konforlu Oturma Alanı
                </h2>
                <p className="leading-relaxed text-base">
                  Yeşil tonlardaki koltuk ve sehpa ile estetik ve konforu bir
                  araya getiriyor.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg7)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg7}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Şık ve Doğal Giriş Alanı
                </h2>
                <p className="leading-relaxed text-base">
                  Giriş alanı tasarımı, detayları ve rahat sandalyesi ile
                  sıcaklık ve estetik katıyor.
                </p>
              </div>
            </div>
            <div className="xl:w-1/4 md:w-1/2 p-4">
              <div
                className="bg-gray-100 p-6 rounded-lg"
                onClick={() => handleImageClick(productImg8)}
              >
                <img
                  className="h-40 rounded w-full object-cover object-center mb-6"
                  src={productImg8}
                  alt="content"
                />
                <h2 className="text-lg font-medium title-font mb-4">
                  Modern ve Ergonomik Çalışma Alanı
                </h2>
                <p className="leading-relaxed text-base">
                  Hem estetik hem de konforlu bir çalışma ortamı.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {modalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
          onClick={closeModal}
        >
          <div className="relative bg-white p-4 rounded w-3/4 max-w-4xl">
            <button
              className="absolute top-0 right-0 m-2 text-gray-600"
              onClick={closeModal}
            >
              &times;
            </button>
            <img
              src={modalImage}
              alt="Enlarged view"
              className="w-full h-auto modal-content"
            />
          </div>
        </div>
      )}
    </>
  );
};
