import React, { useState, useEffect } from "react";
import backgroundImage from "../assets/img2.jpeg";
import backgroundImage2 from "../assets/img3.jpeg";

function HeroSection() {
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 1024);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   handleResize();

  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div className="relative w-screen h-screen overflow-hidden cursor-pointer">
      <img
        // src={isMobile ? backgroundImage2 : backgroundImage}
        src={backgroundImage}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover object-center "
      />
      <div className="absolute inset-0"></div>
    </div>
  );
}

export default HeroSection;
